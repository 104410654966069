import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const AnnouncementStore = persist<any>(
  (set) => ({
    dateRememberAnnouncement: '',
    setDateRememberAnnouncement: (date) => {
      set(() => ({
        dateRememberAnnouncement: date,
      }));
    },
  }),
  {
    name: 'date-remember-announcement',
    partialize: (state) => ({
      dateRememberAnnouncement: state.dateRememberAnnouncement,
    }),
    version: 1,
  },
);

export const useAnnouncementStore = create(AnnouncementStore);
