export enum SlateElementFormatEnum {
  Paragraph = 'paragraph',
  Divider = 'divider',
  PrimaryButton = 'primary-button',
  SecondaryButton = 'secondary-button',
  HeadingFour = 'heading-4',
  HeadingFive = 'heading-5',
  HeadingSix = 'heading-6',
  BulletedList = 'bulleted-list',
  NumberedList = 'numbered-list',
}

export enum AnnouncementShowOnTypeEnum {
  AnnouncementLists = 'announcement-lists',
  Unauthorized = 'unauthorized',
  Authorized = 'authorized',
  Banner = 'banner',
}
