import dayjs from 'dayjs';

const isAnnouncementValid = (
  rememberDate: Date | string,
  latestUpdatedAtDate: Date | string,
): boolean => {
  if (!rememberDate) {
    return true;
  }
  const latestUpdatedAt = dayjs(rememberDate).diff(
    latestUpdatedAtDate,
    'millisecond',
    true,
  );
  return latestUpdatedAt < 0;
};

export default isAnnouncementValid;
