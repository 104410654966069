import type { FC } from 'react';
import React from 'react';
import { cn } from 'class-merge';

const AnnouncementTags: FC<{
  tags: string[];
}> = ({ tags }) => {
  return (
    <div className="grid w-full translate-y-6 grid-cols-4 gap-1 px-6 sm:px-8">
      {tags.map((tag, i) => (
        <div
          className="relative mb-1.5 flex items-center justify-center"
          key={i}
        >
          <div
            className={cn('bg-color-primary absolute h-5 w-full rounded-xl')}
            style={{
              opacity: (100 - i * 10) / 100,
            }}
          />
          <div className="relative z-10 truncate px-2 text-xs font-semibold">
            {tag}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnnouncementTags;
