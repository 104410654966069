'use client';

import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
} from '@packages/icons-react';
import dayjs from 'dayjs';
import { maxBy } from 'lodash';
import type { AnnouncementInterface } from '../types/announcement';
import {
  useAnnouncement,
  useAnnouncementDetail,
} from '~hooks/use-announcement';
import { Dialog } from '~components/dialog';
import AnnouncementDetails from '~components/announcement/announcement-details';
import getImageLists from '~libs/get-image-lists';
import { Button } from '~components/button';
import { useAnnouncementStore } from '~store/announcement-store';
import isAnnouncementValid from '~libs/is-announcement-valid';

const AnnouncementDetailContainer: FC<{
  positionValue: number;
  announce: AnnouncementInterface & {
    objectId?: string;
    cover?: string;
    icon?: string;
    thumbnail?: string;
  };
}> = ({ positionValue, announce }) => {
  const { data: announcementDetail } = useAnnouncementDetail(
    String(announce.objectId),
  );

  return (
    <div className="h-full w-full flex-none p-0.5">
      <AnnouncementDetails
        closable={false}
        content={announcementDetail?.content}
        coverImage={String(announce.cover)}
        description={announce.description || '-'}
        iconImage={String(announce.icon)}
        positionValue={positionValue}
        tags={announce.tags}
        title={announce.title || '-'}
      />
    </div>
  );
};

const AnnouncementContainer: FC<{
  announcements: (AnnouncementInterface & { objectId?: string })[];
  isOpenDialog: boolean;
}> = ({ announcements, isOpenDialog }) => {
  const { dateRememberAnnouncement, setDateRememberAnnouncement } =
    useAnnouncementStore((state) => {
      return {
        dateRememberAnnouncement: state.dateRememberAnnouncement,
        setDateRememberAnnouncement: state.setDateRememberAnnouncement,
      };
    });

  const announcementFiltered = useMemo(() => {
    return announcements.filter((res) =>
      isAnnouncementValid(dateRememberAnnouncement, res.updatedAt),
    );
  }, [announcements, dateRememberAnnouncement]);

  const [isVisibleDialog, setIsVisibleDialog] = useState<boolean>(
    isOpenDialog && Boolean(announcementFiltered.length),
  );
  const [positionAnnounce, setPositionAnnounce] = useState<number>(0);
  const [scrollPositionValue, setScrollPositionValue] = useState<number>(0);

  const disabledPrevAnnounceBth = positionAnnounce === 0;
  const disabledNextAnnounceBth =
    positionAnnounce ===
    (announcementFiltered.length - 1) * scrollPositionValue;
  const hiddenNavigationBtn = announcementFiltered.length === 1;

  const handleCloseAndRemember = (): void => {
    const dateRememberAnnouncedLatest =
      maxBy(announcementFiltered, 'updatedAt')?.updatedAt || dayjs().format();
    setIsVisibleDialog(false);
    setDateRememberAnnouncement(dateRememberAnnouncedLatest);
  };

  const handleChangeScrollPositionValue = (width: number): void => {
    if (width < 400) {
      setScrollPositionValue(354);
      return;
    }
    setScrollPositionValue(504);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      handleChangeScrollPositionValue(window.innerWidth);
    });
    handleChangeScrollPositionValue(window.innerWidth);
    return () => {
      window.removeEventListener('resize', () => {
        handleChangeScrollPositionValue(window.innerWidth);
      });
    };
  }, []);

  return (
    <Dialog
      className="m-0 flex items-center justify-center bg-transparent p-0"
      onClose={() => null}
      open={isVisibleDialog}
      size="xxl"
    >
      <div className="relative mb-20 mt-4 sm:mb-0">
        {isVisibleDialog ? (
          <div className="absolute right-0 top-6 z-20 md:top-16">
            <div className="bg-layout flex flex-col gap-1 rounded-l-xl p-2">
              <Button
                className="bg-color-evaluated h-[30px] w-[30px] rounded"
                custom
                onClick={() => {
                  setIsVisibleDialog(false);
                }}
              >
                <CloseOutlined className="text-xs" />
              </Button>
              {!hiddenNavigationBtn && (
                <>
                  <Button
                    className="bg-color-evaluated h-[30px] w-[30px] rounded"
                    custom
                    disabled={disabledPrevAnnounceBth}
                    onClick={() => {
                      setPositionAnnounce(
                        positionAnnounce - scrollPositionValue,
                      );
                    }}
                  >
                    <CaretLeftOutlined className="text-xs" />
                  </Button>
                  <Button
                    className="bg-color-evaluated h-[30px] w-[30px] rounded"
                    custom
                    disabled={disabledNextAnnounceBth}
                    onClick={() => {
                      setPositionAnnounce(
                        positionAnnounce + scrollPositionValue,
                      );
                    }}
                  >
                    <CaretRightOutlined className="text-xs" />
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : null}
        <div className="max-w-[350px] overflow-hidden sm:max-w-[500px]">
          <div className="flex gap-1">
            {announcementFiltered.map((announce, i) => (
              <AnnouncementDetailContainer
                announce={announce}
                key={i}
                positionValue={positionAnnounce}
              />
            ))}
          </div>
        </div>
        <div className="mb-20 mt-2 flex items-center justify-center font-semibold sm:mb-0">
          <div
            aria-hidden
            className="hover:text-color-primary bg-color-evaluated flex cursor-pointer items-center gap-2 rounded-lg px-4 py-2 text-xs"
            onClick={handleCloseAndRemember}
            role="button"
          >
            <CloseOutlined className="text-xs" /> ปิดโดยไม่ต้องแสดงอีก
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const AnnouncementProvider: FC<{
  children: ReactNode;
  locale: string;
  showOn: string;
}> = ({ children, locale, showOn }) => {
  const { data: announcementLists, isLoading } = useAnnouncement(locale);

  const announcementMapped = useMemo<
    (AnnouncementInterface & { objectId?: string })[]
  >(() => {
    const announceFilteredShowOn =
      announcementLists?.filter(
        (res) => res.showOn.includes(showOn) && res.visibility !== 'hidden',
      ) || [];
    return announceFilteredShowOn.map((announce) => {
      const images = getImageLists(announce?.images || []);
      return {
        ...announce,
        thumbnail: `${process.env.S3_BUCKET_ASSETS_URL}/${images.thumbnailAnnouncement}`,
        cover: `${process.env.S3_BUCKET_ASSETS_URL}/${images.coverAnnouncement}`,
        icon: `${process.env.S3_BUCKET_ASSETS_URL}/${images.iconAnnouncement}`,
        banner: `${process.env.S3_BUCKET_ASSETS_URL}/${images.banner}`,
      };
    });
  }, [announcementLists]);

  if (isLoading) {
    return;
  }

  return (
    <div>
      <AnnouncementContainer
        announcements={announcementMapped}
        isOpenDialog={Boolean(announcementMapped.length)}
      />
      {children}
    </div>
  );
};

export default AnnouncementProvider;
