import { cn } from 'class-merge';
import type { FC } from 'react';
import React from 'react';
import { CloseOutlined } from '@packages/icons-react';
import Image from 'next/image';
import type { SlateContent } from '../../types/announcement';
import AnnouncementTags from './announcement-tags';
import appConfig from '~build-config/config.json';
import SlateContentWidget from '~components/widget/slate-content-widget';

const containerClassName =
  appConfig.announcementDiscordProps?.containerClassName || 'bg-[#1A1B1D]';

const iconClassName =
  appConfig.announcementDiscordProps?.iconClassName || 'border-[#1E1F22]';

const AnnouncementDetails: FC<{
  title: string;
  description: string;
  content: SlateContent[] | undefined;
  coverImage: string;
  iconImage: string;
  tags: string[];
  closable?: boolean;
  positionValue?: number;
  onClose?: () => void;
}> = (props) => {
  const {
    title,
    description,
    content,
    coverImage,
    iconImage,
    tags,
    positionValue,
    closable = true,
    onClose,
  } = props;

  return (
    <div
      className={cn(
        'relative h-full min-h-[300px] max-w-[350px] rounded-3xl duration-1000 sm:max-w-[500px]',
        containerClassName,
      )}
      style={{
        transform: `translateX(-${positionValue}px)`,
      }}
    >
      {closable ? (
        <CloseOutlined
          className="absolute right-4 top-4 z-20 cursor-pointer text-lg"
          onClick={() => {
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
        />
      ) : null}
      <Image
        alt="logo"
        className="h-full w-full rounded-t-2xl"
        draggable={false}
        height={350}
        src={coverImage}
        unoptimized
        width={350}
      />
      <div className="flex w-full -translate-y-12 transform flex-col items-center justify-center gap-1">
        <div
          className={cn(
            'relative flex h-[100px] w-[100px] items-center justify-center rounded-full border-4',
            iconClassName,
          )}
        >
          <Image
            alt="logo"
            className="h-contain w-contain"
            draggable={false}
            height={160}
            src={iconImage}
            unoptimized
            width={160}
          />
          <div className="absolute bottom-1 right-1 h-[18px] w-[18px] rounded-full border-2 border-white/30 bg-[#22A559]" />
        </div>
        <div className="text-color text-center">
          <p className="font-bold">{title}</p>
          <p className="text-sm opacity-70">{description}</p>
        </div>
        <AnnouncementTags tags={tags} />
      </div>
      <div className="min-h-[265px] px-6 pb-6 sm:min-h-[270px] sm:px-8">
        <SlateContentWidget content={content || []} />
      </div>
    </div>
  );
};

export default AnnouncementDetails;
