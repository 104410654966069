import type { FC, ReactNode } from 'react';
import React from 'react';
import { cn } from 'class-merge';
import Link from 'next/link';
import { proxied } from 'radash';
import type { SlateContent } from '../../types/announcement';
import { Button } from '~components/button';
import { SlateElementFormatEnum } from '~constants/announcement';

const getElementClassName = (value: {
  bold?: boolean | undefined;
  italic?: boolean | undefined;
  underline?: boolean | undefined;
  text: string;
}): string => {
  return cn(
    value.bold && 'font-bold',
    value.italic && 'italic',
    value.underline && 'underline',
  );
};

const SlateParagraph: FC<{
  values: SlateContent;
}> = ({ values }) => {
  return (
    <p>
      {values.children.map((value, i) => {
        const className = getElementClassName(value);
        return (
          <span className={className} key={i}>
            {value.text}
          </span>
        );
      })}
    </p>
  );
};

const SlateDivider = (): ReactNode => {
  return <div className="mx-2 mb-3 mt-4 border-t-2 border-white/10 sm:mx-8" />;
};

const SlateButton: FC<{
  values: SlateContent;
}> = ({ values }): ReactNode => {
  const color = proxied((prop) => {
    switch (prop) {
      case SlateElementFormatEnum.PrimaryButton:
        return 'bg-color-primary';
      case SlateElementFormatEnum.SecondaryButton:
        return 'bg-color-evaluated';
      default:
        return 'bg-color';
    }
  });
  return (
    <Link
      className="select-none outline-none"
      href={String(values.url)}
      target="_blank"
    >
      <Button className={cn('my-2 h-[50px] w-full', color[values.type])} custom>
        <SlateParagraph values={values} />
      </Button>
    </Link>
  );
};

const SlateHeading = ({ values }): ReactNode => {
  const headingTypes = proxied((prop: SlateElementFormatEnum) => {
    switch (prop) {
      case SlateElementFormatEnum.HeadingFour:
        return (
          <h4 className="text-md font-bold">
            <SlateParagraph values={values} />
          </h4>
        );
      case SlateElementFormatEnum.HeadingFive:
        return (
          <h5 className="text-sm font-bold">
            <SlateParagraph values={values} />
          </h5>
        );
      case SlateElementFormatEnum.HeadingSix:
        return (
          <h6 className="text-xs font-bold">
            <SlateParagraph values={values} />
          </h6>
        );
      default:
        return <SlateParagraph values={values} />;
    }
  });
  return headingTypes[values.type];
};

const SlateBulletedList = ({ values }): ReactNode => {
  return (
    <ul className="list-disc px-4 sm:px-4">
      {values.children.map((list, i) => (
        <li key={i}>
          <SlateParagraph values={list} />
        </li>
      ))}
    </ul>
  );
};

const SlateNumberedList = ({ values }): ReactNode => {
  return (
    <ol className="list-decimal px-4 sm:px-4">
      {values.children.map((list, i) => (
        <li key={i}>
          <SlateParagraph values={list} />
        </li>
      ))}
    </ol>
  );
};

const slateElements = proxied((prop) => {
  switch (prop) {
    case SlateElementFormatEnum.Paragraph:
      return {
        children: (values) => {
          return <SlateParagraph values={values} />;
        },
      };
    case SlateElementFormatEnum.Divider:
      return {
        children: () => {
          return <SlateDivider />;
        },
      };
    case SlateElementFormatEnum.PrimaryButton:
    case SlateElementFormatEnum.SecondaryButton:
      return {
        children: (values: SlateContent) => {
          return <SlateButton values={values} />;
        },
      };
    case SlateElementFormatEnum.HeadingFour:
    case SlateElementFormatEnum.HeadingFive:
    case SlateElementFormatEnum.HeadingSix:
      return {
        children: (values: SlateContent) => {
          return <SlateHeading values={values} />;
        },
      };
    case SlateElementFormatEnum.BulletedList:
      return {
        children: (values: SlateContent) => {
          return <SlateBulletedList values={values} />;
        },
      };
    case SlateElementFormatEnum.NumberedList:
      return {
        children: (values: SlateContent) => {
          return <SlateNumberedList values={values} />;
        },
      };
    default:
      return {
        children: () => '',
      };
  }
});

const SlateContentWidget: FC<{
  content: SlateContent[];
}> = ({ content }) => {
  return (
    <div className="flex flex-col">
      {content.map((detail, i) => {
        return <div key={i}>{slateElements[detail.type].children(detail)}</div>;
      })}
    </div>
  );
};

export default SlateContentWidget;
