import type { AnnouncementInterface } from '../types/announcement';
import type { Config, Return } from '~hooks/use-request';
import useRequest from '~hooks/use-request';

export const useAnnouncement = (
  lang: string,
  config?: Config<AnnouncementInterface[]>,
): Return<AnnouncementInterface[], unknown> => {
  return useRequest(
    {
      url: '/announcements',
      params: {
        lang,
      },
    },
    config,
  );
};

export const useAnnouncementDetail = (
  objectId: string,
  config?: Config<AnnouncementInterface>,
): Return<AnnouncementInterface, unknown> => {
  return useRequest(
    objectId
      ? {
          url: `/announcements/${objectId}`,
          params: {
            objectId,
          },
        }
      : undefined,
    config,
  );
};
