import { objectify } from 'radash';

interface ImageInterface {
  type: string;
  alt: string;
  url: string;
  name: string;
  value: string;
}

const getImageLists = (
  images: Partial<ImageInterface>[],
): Record<string, string> => {
  return objectify(
    images,
    (values: any) => values.type || values.name,
    (values) => values.url || values.value,
  );
};

export default getImageLists;
